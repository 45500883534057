import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CookiePopup = () => {
  let navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("cookieConsentAccepted")) {
      setShowPopup(true);
    }
  }, [showPopup]);

  const acceptCookies = () => {
    localStorage.setItem("cookieConsentAccepted", true);
    window.gtag("event", "page_view", {
      page_path: window.location.pathname,
    });
    setShowPopup(false);
  };

  const rejectCookies = () => {
    document.querySelector(".cookie-consent .toast").classList.remove("show");
  };

  const goToPolicy = () => {
    navigate(`/CookiePolicy`);
    window.scrollTo(0, 0);
  };

  return (
    showPopup && (
      <div className="custom-toast cookie-consent">
        <div
          className={`toast fade show`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto">Cookie Consent</strong>
          </div>
          <div className="toast-body">
            <p>
              This website uses cookies for tracking purposes. To learn more,
              visit our{" "}
              <button
                type="button"
                className="cookiePolicyBtn"
                onClick={goToPolicy}
              >
                Cookie Policy
              </button>
            </p>
            <button
              className="btn btn-primary accept"
              type="button"
              onClick={acceptCookies}
            >
              Accept
            </button>
            <button
              className="btn btn-outline reject"
              type="button"
              onClick={rejectCookies}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookiePopup;
