import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import RightArrow from "../../assets/images/right-arrow.png";
import Linkedin from "../../assets/images/linkedin.png";
import Twitter from "../../assets/images/twitter.png";
import Youtube from "../../assets/images/youtube.png";
import CloseIcon from "../../assets/images/close-icon.png";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";

const Footer = () => {
  let Button = useRef();
  let navigate = useNavigate();

  const [toast, setToast] = useState({
    toastHeader: null,
    toastBody: null,
    showToast: false,
  });

  const form = useForm();
  const { register, control, handleSubmit, formState, reset } = form;
  const { errors } = formState;

  useEffect(() => {
    let FooterLinks = document.querySelectorAll(".footer a");
    FooterLinks.forEach(function (item) {
      item.addEventListener("click", function () {
        window.scrollTo(0, 0);
      });
    });
    Button.current.addEventListener("click", function () {
      navigate(`/ContactUs`);
      window.scrollTo(0, 0);
    });
  }, [navigate]);

  const subscribeToNewsletter = async (data) => {
    if (!localStorage.getItem("cookieConsentAccepted")) {
      window.gtag("event", "submit", {
        event_category: "Form",
        event_label: "Newsletter Form",
      });
    }
    data["type"] = "newsletter";
    const res = await fetch(
      "https://j7yx88a011.execute-api.eu-west-2.amazonaws.com/development/submit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "z1ZYvWhWy45xrq9L1spGWazcXACPUFiJ3OkKKRs8",
        },
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      throw new Error("Network response was not ok");
    }
    reset();
    const result = await res.json();
    setToast({
      toastBody: result["message"],
      toastHeader: "Subscribed!",
      showToast: true,
    });
    setTimeout(function () {
      closeToast();
    }, 10000);
  };

  const closeToast = () => {
    setToast({
      toastHeader: null,
      toastBody: null,
      showToast: false,
    });
  };

  return (
    <footer className="footer">
      <div className="custom-toast">
        <div
          className={`toast fade ${toast.showToast ? "show" : "hide"}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto">{toast.toastHeader}</strong>
            <button type="button" className="btn-close" onClick={closeToast}>
              <img src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="toast-body">{toast.toastBody}</div>
        </div>
      </div>
      <div className="container">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <div className="footer-newsletter">
                <h3>Subscribe to our newsletter</h3>
                <p>
                  Get a summary of what we’ve shipped during the last month,
                  <br />
                  behind the scenes updates, and team picks.
                </p>
                <form onSubmit={handleSubmit(subscribeToNewsletter)} noValidate>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Email"
                      className={errors?.email ? "error" : ""}
                      {...register("email", {
                        required: "Please enter valid email.",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email address.",
                        },
                      })}
                    />
                    {errors?.email && (
                      <p className="error-msg">{errors.email.message}</p>
                    )}
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Subscribe"
                    />
                  </div>
                </form>
              </div>
              <div className="footer-details">
                <h3>Get in Touch </h3>
                <p>Get in-touch with us for any special requirements!</p>
                <button className="btn btn-primary" ref={Button}>
                  Contact Us <img src={RightArrow} alt="right-arrow" />
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="footer-links">
                <h2>
                  Checkout these links <br />
                  before you go!
                </h2>
                <div className="row m-0">
                  <div className="col-6 p-0">
                    <ul>
                      <li>
                        <h2>Company</h2>
                      </li>
                      <li>
                        <NavLink to="/AboutUs">About Us</NavLink>
                      </li>
                      <li>
                        <button
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Case Studies
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink
                              className="dropdown-item"
                              to="/HumanRights"
                            >
                              Human Rights
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className="dropdown-item"
                              to="/SupplyChain"
                            >
                              Supply Chain
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className="dropdown-item"
                              to="/RenewableEnergy"
                            >
                              Renewable Energy
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink to="/Faq">FAQs</NavLink>
                      </li>
                      <li>
                        <NavLink to="/ContactUs">Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 p-0">
                    <ul className="second">
                      <li>
                        <h2>Legal</h2>
                      </li>
                      <li>
                        <NavLink to="/CookiePolicy">Cookies Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/PrivacyPolicy">Privacy Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/TermsOfService">Terms of Service</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-social">
                  <p>© 2024 ESGATE. All rights reserved.</p>
                  <ul>
                    <li>
                      <a href="https://www.linkedin.com/company/esgate/">
                        <img src={Linkedin} alt="linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCHC1kl97WQSa8HrYY1MGPXw">
                        <img src={Youtube} alt="youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/ESGATE_">
                        <img src={Twitter} alt="twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </footer>
  );
};

export default Footer;
