import React, { useState, useEffect } from "react";
import ContactusBg from "../assets/images/contact-bg.png";
import CloseIcon from "../assets/images/close-icon.png";
import { useForm } from "react-hook-form";

// import { DevTool } from "@hookform/devtools";

const ContactUs = () => {
  useEffect(() => {
    if (!localStorage.getItem("cookieConsentAccepted")) {
      window.gtag("event", "page_view", {
        page_path: window.location.pathname,
      });
    }
  });
  const form = useForm();
  const { register, control, handleSubmit, formState, reset } = form;
  const { errors } = formState;

  const [toast, setToast] = useState({
    toastHeader: null,
    toastBody: null,
    showToast: false,
  });

  const onSubmit = async (data) => {
    if (!localStorage.getItem("cookieConsentAccepted")) {
      window.gtag("event", "submit", {
        event_category: "Form",
        event_label: "Contact Form",
      });
    }
    data["type"] = "contact-form";
    const res = await fetch(
      "https://j7yx88a011.execute-api.eu-west-2.amazonaws.com/development/submit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "z1ZYvWhWy45xrq9L1spGWazcXACPUFiJ3OkKKRs8",
        },
        body: JSON.stringify(data),
      }
    );
    const response = await res.json();
    if (!res.ok) {
      setToast({
        toastBody: response?.message,
        toastHeader: "Technical Glitch!",
        showToast: true,
      });
    }
    reset();
    setToast({
      toastBody: response?.message,
      toastHeader: "Success",
      showToast: true,
    });
    return;
  };

  const closeToast = () => {
    setToast({
      toastHeader: null,
      toastBody: null,
      showToast: false,
    });
  };

  return (
    <div className="contact-us">
      <div className="custom-toast">
        <div
          className={`toast fade ${toast.showToast ? "show" : "hide"}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto">{toast.toastHeader}</strong>
            <button type="button" className="btn-close" onClick={closeToast}>
              <img src={CloseIcon} alt="" />
            </button>
          </div>
          <div className="toast-body">{toast.toastBody}</div>
        </div>
      </div>

      <div className="contact-us-bg">
        <img src={ContactusBg} alt="" />
      </div>

      <div className="container">
        <div className="contact-us-heading">
          <h2>
            <span>Contact us</span>
            Let's Start a Conversation
          </h2>
          <p>
            Whether you have a question or need more information, we're here to
            listen. Your thoughts and feedback are important to us, and we're
            always eager to start a conversation.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <h3>
            Send us a Message!
            <span>Write to us. We usually get back to you in 24 hours. </span>
          </h3>

          <div className="form-group-main">
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter first name"
                className={errors?.firstname ? "error" : ""}
                {...register("firstname", {
                  required: "Please enter Firstname.",
                })}
              />
              {errors?.firstname && (
                <p className="error-msg">{errors.firstname?.message}</p>
              )}
            </div>

            <div className="form-group">
              <label>Last Name</label>
              <input
                name="lastname"
                type="text"
                placeholder="Enter last name"
                {...register("lastname", {})}
              />
            </div>
          </div>

          <div className="form-group">
            <label>Company Name</label>
            <input
              name="organisation"
              type="text"
              placeholder="Enter company name"
              {...register("organisation", {})}
            />
          </div>

          <div className="form-group">
            <label>Your Email</label>
            <input
              name="email"
              type="email"
              placeholder="Enter email address"
              className={errors?.email ? "error" : ""}
              required
              {...register("email", {
                required: "Required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address.",
                },
              })}
            />
            {errors?.email && (
              <p className="error-msg">{errors.email.message}</p>
            )}
          </div>

          <div className="form-group">
            <label>Message</label>
            <textarea
              name="message"
              placeholder="Message"
              className={errors?.message ? "error" : ""}
              {...register("message", { required: "Please enter message." })}
            ></textarea>
            {errors?.message && (
              <p className="error-msg">{errors.message.message}</p>
            )}
          </div>

          <div className="form-group">
            <button className="btn btn-primary">Send Message</button>
          </div>
        </form>

        <div className="contact-us-details">
          <div className="row">
            <div className="col-lg-6 col-12">
              <h3>Working hours</h3>
              <p>
                Monday To Friday
                <span>9:00 AM to 8:00 PM </span>
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <h3>Contact Us</h3>
              <p>
                <a href="tel:902193461">902 19 34 61</a>
                <span>
                  <a href="mailto:info@esgate.es">info@esgate.es</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default ContactUs;
