import React from 'react'

const CookiePolicy = () => {
    return (
        <div className="policy">
            <div className="container">
                <div className="policy-heading">
                    <h2>Cookies Policy</h2>
                    <p>Last updates 20.05.2024</p>
                </div>
                <div className="policy-content">
                    <p>
                        At ESGATE, we believe in being clear and open about how we collect and use data related
                        to you. In the spirit of transparency, this policy provides detailed information about how
                        and when we use cookies. This cookie policy applies to any ESGATE product or service
                        that links to this policy or incorporates it by reference.
                    </p>
                    <h3>What are cookies?</h3>
                    <p>
                        Cookies are small text files sent to your computer or mobile device when you visit a
                        website. They are unique to your account or your browser. Cookies are sent back to the
                        originating website on each subsequent visit, or to another website that recognizes that
                        cookie, to develop a record of the user’s online activity.
                    </p>
                    <h3>Why do we use cookies?</h3>
                    <p>
                        We use cookies to enhance your experience on our website by:
                        <ul>
                            <li>Remembering your preferences and settings.</li>
                            <li>Helping with sign-in and providing feedback.</li>
                            <li>Providing you with interest-based ads.</li>
                            <li>Analyzing how our services are being used.</li>
                        </ul>
                    </p>
                    <h3>Types of cookies used by ESGATE:</h3>
                    <p>
                        Essential Cookies: These cookies are crucial for you to browse our website and use its
                        features. <br /><br />
                        Performance and Analytics Cookies: We use Google Analytics cookies to collect
                        information about how visitors use our website. These cookies collect information in the
                        aggregate to give us insight into how our website is being used.<br /><br />
                        Functionality Cookies: These cookies allow our websites to remember choices you make
                        while browsing.<br /><br />
                        Advertising Cookies: These cookies are used to make advertising messages more relevant
                        to you. They perform functions like preventing the same ad from continuously
                        reappearing, ensuring that ads are properly displayed for advertisers, and in some cases
                        selecting advertisements that are based on your interests.
                    </p>
                    <h3>Google Analytics</h3>
                    <p>
                        Our website uses Google Analytics, a service provided by Google, Inc. Google Analytics
                        uses cookies to help us analyze how users use the site. The information generated by the
                        cookie about your use of the website (including your IP address) will be transmitted to and
                        stored by Google on servers in the United States.
                    </p>
                    <h3>How can you control cookies?</h3>
                    <p>
                        You have the right to decide whether to accept or reject cookies. You can set or amend
                        your web browser controls to accept or refuse cookies. If you choose to reject cookies,
                        you may still use our website though your access to some functionality and areas of our
                        website may be restricted. For more information on how to adjust your browser settings,
                        refer to your browser’s help menu.
                    </p>
                    <h3>Changes to this Cookie Policy</h3>
                    <p>
                        We may update this Cookie Policy to reflect, for example, changes to the cookies we use
                        or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie
                        Policy regularly to stay informed about our use of cookies and related technologies.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CookiePolicy